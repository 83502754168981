<template>
  <Content class="main-support" v-if="$locale">
    <Header :options="{ remove: ['user'] }" v-if="!isEmbed">
      <template v-slot:user>
        <a class="avatar close-btn" @click="goHome">
          <figure><iconic name="times" /></figure>
        </a>
      </template>
    </Header>
    <div class="main-support-content" :embed="isEmbed">
      <Sticky class="main-support-title" :embed="isEmbed">
        <div class="main">{{ $route.meta.title }}</div>
      </Sticky>
      <div class="main-support-list">
        <div class="main">
          <Collapse ref="collapse" :title="$route.meta.subtitle" :settings="{ opened: true, disabled: true }">
            <div class="collapse-container semantic">
              <Terms v-if="$route.meta.component === 'terms'" />
              <Privacy v-if="$route.meta.component === 'privacy'" />
              <USC2257 v-if="$route.meta.component === 'USC2257'" />
              <DMCA v-if="$route.meta.component === 'DMCA'" />
              <Billing v-if="$route.meta.component === 'Billing'" />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  </Content>
</template>
<script>
export default {
  props: ["component"],
  components: {
    Header: () => import("../home/Header.vue"),
    Sticky: () => import("../widgets/Sticky.vue"),
    Content: () => import("../widgets/Content.vue"),
    Collapse: () => import("../widgets/Collapse.vue"),
    Terms: () => import("./Terms.vue"),
    Privacy: () => import("./Privacy.vue"),
    USC2257: () => import("./USC2257.vue"),
    DMCA: () => import("./DMCA.vue"),
    Billing: () => import("./Billing.vue"),
  },
  computed: {
    isEmbed: function() {
      return this.$route.query.mode === "embed";
    },
    pageTitle: function() {
      return this.$route.meta.title;
    },
  },
  mounted: function() {
    document.title = this.$route.meta.subtitle;
  },
};
</script>

<style lang="scss">
.main-support {
  line-height: 1.3334;
  &-content {
    margin: $header_height 0 0 0;
    &[embed="true"] {
      background: #fff;
      margin: 0 0 0 0;
    }
  }
  &-title,
  &-titleSection {
    font-size: 2rem;
  }
  &-title {
    padding: $mpadding;
    background-color: #fff;
    z-index: 1;
    &[embed="true"] {
      top: 0 !important;
      padding: 0 0 $mpadding/2 0;
    }
  }
  &-titleSection {
    padding: $mpadding $mpadding 0 $mpadding;
    font-size: 1.5rem;
  }
  &-list {
    padding: $mpadding;
    > .main {
      background-color: #fff;
      border: solid 1px $alto;
      border-radius: $mpadding/4;
      overflow: hidden;
    }
    .collapse {
      &:not(:last-child) {
        border-bottom: solid 1px $alto;
      }
      &.open {
        .collapse-header {
          border-bottom: solid 1px $alto;
        }
      }
      &-header {
        padding: $mpadding $mpadding;
        font-weight: bold;
      }
      &-container {
        padding: $mpadding $mpadding;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    font-size: 100%;
    font-weight: normal;
  }

  .contents {
    font-size: 120%;
  }

  .__section_gap {
    padding: 0 0 $mpadding * 3 0;
  }

  @include screen($tablet_width) {
    &-list {
      .collapse {
        &-header {
          padding: $mpadding $mpadding;
        }
        &-container {
          padding: $mpadding * 2;
        }
      }
    }
  }
}
</style>
